/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.registration .input-group-prepend {
    width: 34px;
    margin-top: 2px;
    height: $input-height;

    .input-group-text {
        border-right: 0;
        background-color: transparent;
    }
}

.registration #cardExpiry {
    border-left: 0;
}
// .StripeElement {
//     background-color: #fff !important;
//     padding: 11px 3px 3px 8px !important;
//     border-radius: 0 !important;
//     height: $input-height !important;
//     border: 1px solid #ccd0d2 !important;
//     // box-shadow: 0 1px 3px 0 #e6ebf1;
//     // transition: box-shadow 150ms ease;
//     box-shadow: initial !important;
//     transition: initial !important;
// }
// //[attr$=valeur]
// .StripeElement--focus {
//    border: 1px solid #98cbe8 !important;
//    border-color:  !important;
//    outline: 0 !important;
//    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(152, 203, 232, 0.6) !important;
//    z-index: 100;
// }
//
// .StripeElement--invalid {
//    border-color: #fa755a !important;
// }
//
// .StripeElement--webkit-autofill {
//    background-color: #fefde5 !important;
// }
// .modal-stripe {
//     #cardNumber.StripeElement {
//         border-bottom-color: transparent !important;
//     }
//
//     #cardExpiry.StripeElement {
//         border-right: 0 !important;
//     }
//
//     #cardCvc.StripeElement {
//         border-left-color: transparent !important;
//     }
// }
