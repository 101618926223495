.suggested-articles {
    .row,
    .table {
        margin-right: 0;
        margin-left: 0;
    }
    aside {
        transition: all 200ms ease-in;
        figure {
            span {
                display: block;
                width: 100%;
                height: 140px; //max-height: 160px;
                //min-height: 100px;
                //                background-size: cover;
                //                background-position: center center;
                background-color: lighten($dark, 60%);
                overflow: hidden;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover; //                mix-blend-mode: multiply;
                    transition: all 200ms ease-in;
                } //                &:before {
                //                    content: '';
                //                    transition: all 200ms ease-in;
                //                    display: block;
                //                    width: 100%;
                //                    height: 100%;
                //                    top: 100%;
                //                    left: 0;
                //                    background: url('/images/logo-pe-white-short.svg') no-repeat scroll center center;
                //                    background-size: 40px;
                //                    position: absolute;
                //                    z-index: 2;
                //
                //                    @media (max-width: 768px) {
                //                        display: none;
                //                    }
                //
                //                }
            }


        }
    }
    aside:hover {
        //           background: lighten($primary, 35%);
        //        padding-left: 15px;
        figure {
            span {
                img {

                    @media (min-width: 768px) {
                        transform: scale(1.1);
                    }
                } //                &:before {
                //                    top: 0;
                //                }
            }
        }

        a {
            text-decoration: none;
        }
    }


}

.categories h1 small {
    font-size: .5em;
}

.categories h1 {
    border-left: solid 20px $primary;
    padding-left: 15px; //    @media screen and (max-width: 576px) {
    //        margin-left: -15px;
    //    }
}

/* for sections after articles */

nav.suggested-articles {

    .card {

        & > a {
            display: block;
            width: 100%;
            height: 100%;
            min-height: 80px;
            background-color: lighten($dark, 60%);
            overflow: hidden;
            position: relative;


            flex: 0 0 80px;
            @media (min-width: $md) {
                flex: 0 0 125px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 200ms ease-in;
            }

        }

        &:hover {

            & > a {
                img {

                    @media (min-width: $md) {
                        transform: scale(1.1);
                    }
                }

            }

        }

        .card-body {

            h3 {
                margin-top: 0;

            }

        }

    }

    /* child pages catégory */
    a {
        & > .card {
            & > .img {
                height: 140px;
                background-color: lighten($dark, 60%);
                overflow: hidden;
                position: relative;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all 200ms ease-in;
                }


            }

            .card-body {
                span {
                    display: block;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }
    }

    a:hover {
        text-decoration: none;

        & > .card > .img > img {
            @media (min-width: 768px) {
                transform: scale(1.1);
            }

        }

    }
}
