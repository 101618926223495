/* Sharing buttons */
.btn-share {

    position: relative;

    &:hover {
        @media (min-width : 768px) {
            sup {
                display: inline-block;
            }
        }
    }

    sup {
        position: absolute;
        bottom: auto;
        top: -3em;
        left: .55em;
        display: none;
    }

    &.btn-facebook,
    &.btn-google,
    &.btn-linkedin,
    &.btn-pinterest,
    &.btn-tumblr,
    &.btn-twitter {
        cursor: pointer;
        color: #999;
        background: transparent;
    }

    &.btn-facebook:hover {
        color: #49659F;
        background: transparent;
    }

    &.btn-twitter:hover {
        color: #55ACEE;
        background: transparent;
    }

    &.btn-google:hover {
        color: #DB4437;
        background: transparent;
    }

    &.btn-pinterest:hover {
        color: #bf0020;
        background: transparent;
    }

    &.btn-tumblr:hover {
        color: #314358;
        background: transparent;
    }

    &.btn-linkedin:hover {
        color: #0274b3;
        background: transparent;
    }
}

/* Social Box */
.social-box {
    position: fixed;
    left: -5px;
    top: 12%;
    background: #F8F8F8;
    padding: 20px 10px 10px 15px;
    border: 1px solid #eee;
    border-radius: 5px;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.1);

    .social-google {
        padding-left: 5px;
    }

    .social-facebook {
        padding: 5px;
    }

    .social-twitter {
        padding: 5px;
    }
}

.social-connect {

    .hr-ou {

        margin-top: 2em;
        border-top: solid 1px #dadada;
        margin-bottom: .5em;
        position: relative;
        text-align: center;

        &:before {
            color: $grey;
            content: attr(data);
            position: relative;
            top: -.7em;
            //left: calc(50% - 1.5em);
            width: 100%;max-width: fit-content;
            //height: 2em;
           // text-align: center;
            //line-height: 2em;
            padding: .5em;
            background: white;
        }

    }

    a {
        position: relative;
        padding-left: 1.9em;

        .btn-share {
            position: absolute;
            left: 0;
            top: -.15em;
        }

        .btn-facebook {
            color: #3b5998;
        }

        .btn-google {
            color: #dd4b39;
        }

        &:hover {

            .btn-facebook {
               color: #2d4373;
            }

            .btn-google {
                color: #c23321;
            }

        }

    }

}

/* Social Btns */
.btn-facebook {
    color: #fff;
    background-color: #3b5998;
    border-color: rgba(0, 0, 0, 0.2);

    &:hover {
        background-color: #2d4373;
        border-color: rgba(0, 0, 0, 0.2);
    }
}

.btn-google {
    color: #fff;
    background-color: #dd4b39;
    border-color: rgba(0, 0, 0, 0.2);

    &:hover {
        color: #fff;
        background-color: #c23321;
        border-color: rgba(0, 0, 0, 0.2);
    }
}

.btn-pinterest {
    color: #fff;
    background-color: #dd4b39;
    border-color: rgba(0, 0, 0, 0.2);

    &:hover {
        color: #fff;
        background-color: #c23321;
        border-color: rgba(0, 0, 0, 0.2);
    }
}

.btn-twitter {
    color: #fff;
    background-color: #55acee;
    border-color: rgba(0, 0, 0, 0.2);

    &:hover {
        color: #fff;
        background-color: #2795e9;
        border-color: rgba(0, 0, 0, 0.2);
    }
}

.btn-tumblr {
    color: #fff;
    background-color: #55acee;
    border-color: rgba(0, 0, 0, 0.2);

    &:hover {
        color: #fff;
        background-color: #314358;
        border-color: rgba(0, 0, 0, 0.2);
    }
}

.btn-linkedin {
    color: #fff;
    background-color: #55acee;
    border-color: rgba(0, 0, 0, 0.2);

    &:hover {
        color: #fff;
        background-color: #0274b3;
        border-color: rgba(0, 0, 0, 0.2);
    }
}

.btn-social {
    position: relative;
    padding: 8px 12px 8px 48px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: initial !important;
    font-weight: initial !important;
    font-size: 1em !important;
    //min-width: 200px;
    //width: 90%;
    border-radius: 4px !important;
    border: 1px solid transparent !important;

    > :first-child {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 37px;
        line-height: 37px;
        // font-size: 1.6em;
        text-align: center;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
    }

    &:focus,
    &:hover {
        color: #fff;
    }
}
