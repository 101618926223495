@import 'form-stripe';
@import 'form-vue-select';

label {
    font-weight: 500;
}

label[for] {
    cursor: pointer;
}
// Antispam
.form-email2 {
    display: none;
}

.custom-select,
.form-control {
    height: $input-height;
    margin: 2px 0 4px;
    //border-radius: 0 !important;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    .form-control {
        font-size: 16px;
    }
    // &:focus {
    //     font-size: 1rem;
    // }
}

.form-control {
    padding: 3px 3px 0 8px;
    background: transparent;
    background: #fff;
    //border-width: 2px;
    //box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

    &.readonly {
        overflow: hidden;
        white-space: nowrap;
    }

    &:focus {
        border-color: #2196F3;
        box-shadow: 0 0 0 !important;
        //box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 2px rgba(33, 150, 243, 0.4);
    }

    &.input-date {
        width: 135px;
        padding-right: 0 !important;
    }
}

input.form-control {
    line-height: 1em;
}

.custom-select,
.form-control {
    &.custom-select-sm,
    &.form-control-sm {
        height: 30px;
        min-height: 30px;
    }
}

textarea.form-control {
    height: auto;
}

.form-control-plaintext {
    padding-top: 9px;

    &.readonly {
        overflow: hidden;
        white-space: nowrap;
    }
}

.input-group-append {
    margin-top: 2px;
    height: $input-height;

    .input-group-text {
        background-color: rgba(241, 241, 241, .6);
    }
}

// form-control-feedback
// .input-group {
//     width: 100%;
//     // width: 30%;
//     // min-width: 135px;
//
//     .input-group-addon {
//         background: #fff;
//         border: 1px solid #ccd0d2;
//         border-radius: 0;
//         z-index: 1;
//
//         &:last-child {
//             padding: 5px 3px;
//             border: 0;
//             margin-top: 5px;
//             margin-left: -33px;
//             display: inline-block;
//             position: absolute;
//             background: #fff;
//             width: 32px;
//         }
//     }
// }

.col-form-label {
    padding-top: 10px;

    @media screen and (min-width:$lg) {
        text-align: right;
    }
}

.input-group {
    .input-group-btn {
        .btn {
            margin-top: 2px;
            margin-bottom: 4px;
            height: $input-height;
            z-index: 99;

            &.btn-default {
                background: #F8F8F8;
            }

            &[disabled] {
                border-color: #ced4da;
            }
        }
    }
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    text-shadow: 0 -1px 0 #fff;
    color: #666;
}

.has-feedback {
    position: relative;

    &:focus,
    &:focus-within {
        .form-control-feedback {
            .far,
            .fas {
                color: #2196f3;
            }
        }
    }

    .form-control-feedback {
        position: absolute;
        top: 10px !important;
        right: 2px !important;
        margin-right: 11px;
        color: #999;

        .far,
        .fas {
            margin-right: 12px !important;
            // margin-right: 0; // Décalage CB ?
        }
    }
}

.has-envelope {
    // background-image:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='20px' height='15px'><text x='0' y='15' font-size='14px'>\F0E0</text></svg>");
    padding-right: 2.25rem;
    background-repeat: no-repeat;
    background-position: center right 0.5625rem;
    background-size: 1.125rem 1.125rem;
}

div.mce-edit-area {
    background: #FFF;
    filter: none;
    padding: 8px 3px 6px 8px;
}

.shad {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
}

.noshad {
    box-shadow: initial !important;

    &:hover {
        box-shadow: initial;
    }
}

.table-inputs {
    tr {
        td {
            padding: 0;
            vertical-align: middle;
            border: 0;

            .form-control {
                margin: 0;
                border-bottom-color: transparent;
            }

            &:last-child {
                .form-control {
                    border-left-color: transparent;
                }
            }
        }

        &:last-child {
            td {
                .form-control {
                    border-bottom-color: #ccd0d2;
                }
            }
        }
    }

    tr {
        td {
            .form-control {
                &:focus {
                    border-bottom-color: #2196F3;
                    border-left-color: #2196F3;
                }
            }
        }
    }

    > tbody {
        > tr {
            &:first-child td {
                padding-top: 10px;
            }

            &:last-child td {
                padding-bottom: 10px;
            }
        }
    }
}

body {
    &#tinymce,
    &html {
        background-color: #fff;
    }

    &#tinymce {
        div,
        p {
            padding-top: 10px;
            border: 1px dashed #BBB;
        }

        div {
            background: transparent no-repeat url("data:image/gif;base64,R0lGODlhEgAKAIABALu7u////yH5BAEAAAEALAAAAAASAAoAAAIfjI9poI0cgDywrhuxfbrzDEbQM2Ei5aRjmoySW4pAAQA7");
        }

        p {
            background: transparent no-repeat url("data:image/gif;base64,R0lGODlhCQAJAJEAAAAAAP///7u7u////yH5BAEAAAMALAAAAAAJAAkAAAIQnG+CqCN/mlyvsRUpThG6AgA7");
        }

        div.row {
            padding: 0;
            margin: 0;
            border: 0;
            background: initial;
        }
    }
}

.custom-select.is-invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
    z-index: 999;
    position: relative;
    border: 1px solid #{$red};
}
