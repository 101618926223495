/*.columns {
    text-align: center;

    .column {
        //display: inline-block;
        float: none;
        text-align: left;
        vertical-align: bottom;
        margin-right: -4px;
        padding: 8px;

        .price {
            list-style-type: none;
            border: 1px solid #eee;
            margin: 0;
            padding: 0;
            transition: 0.3s;
            // &:hover {
            //     box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2);
            // }
            .header {
                // background-color: #3D92E5;
                // color: white;
                font-size: 25px;
                font-weight: 400;
            }

            .body {
                //min-height: 155px;
                //text-align: left;
            }

            li {
                background-color: #fff;
                border-bottom: 1px solid #eee;
                padding: 20px;
                text-align: center;

                &.grey {
                    background-color: rgba(255, 255, 255, .4);
                    font-size: 20px;
                }

                &.item {
                    text-align: left;
                    padding: 8px 15px;
                    font-size: 13px;

                    &:nth-of-type(1n+8) {
                        display: none;
                    }

                    &:last-child {
                        display: block;
                    }
                }

                &.billing {
                    // min-height: 140px;
                    // text-align: left;
                    padding: 8px auto;

                    .lite {
                        color: #999;
                    }

                    .big {
                        font-size: 35px;
                        color: #333;
                    }
                }

                .btn {
                   // margin-bottom: 5px;
                    //padding: 10px 25px;
                    //font-size: 18px;
                    // border: none;

                    &.btn-primary {
                       // background-color: #3D92E5;
                        //color: #fff;
                    }
                }
            }
        }
              
    }

    &.colums-bottom {
        vertical-align: top;
        margin-top: -18px;

        .column {
            vertical-align: top;
        }
    }
}
*/
//.column-preview {
//    a:focus { box-shadow: 0 0 0 0 rgba(58, 152, 246, 0); }
//    a[aria-expanded="false"] > i:before {
//        content: "\f107";
//    }
//    a[aria-expanded="true"] > i:before {
//        content: "\f106";
//    } 
//    .list-group-item {
//        &:nth-child(odd){
//            //background: lighten($light, 5%);
//        }
//    } 
//}

.choix-facturation {
    
    font-size: 120%;
    @media(min-width:$md){
        font-size: 100%;
    }
    
}

/*
@media only screen and (max-width: 600px) {
    .columns {
        .column {
            width: 100%;
        }
    }
}

.frequency {
    small {
        // display: block;
        text-align: right;
        line-height: .7em;
        font-size: 75%;
    }
}
*/