/* Comments */
ul.comments {
    list-style: none;
    margin-top: 30px;
    padding-left: 0;

    p.meta {
        border-bottom: 1px dashed #eee;
        padding-bottom: 8px;
    }

    ul {
        margin-left: 85px;
    }

    > li > img {
        position: relative;
        float: left;
        margin-left: 0;
        margin-top: 0;
        width: 46px;
        height: 46px;
        box-shadow: none;
    }

    .post-comments {
        border: 1px solid rgba(238, 238, 238, .8);
        margin-bottom: 20px;
        margin-left: 65px;
        margin-right: 0;
        padding: 10px 20px;
        position: relative;
        border-radius: 4px;
        background: rgba(248, 248, 248,.4);
        color: #6b6e80;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 8px;
            left: -10px;
            border: 10px;
            border-top: 10px solid transparent;
            border-right: 10px solid rgba(248, 248, 248,.8);
            border-bottom: 10px solid transparent;
            border-left: 0 solid transparent;
        }
    }
}

section.section--discrete {
    ul.comments {
        .post-comments {
            border-color: #fff;
            background-color: #fff;

            &:before {
                border-right-color: #fff;
            }
        }
    }
}
