input[type="checkbox"],
input[type="radio"] {
    position: absolute;
    margin: 2px 0 0;
    z-index: 0;
    display: none;
}

input[type="checkbox"]+label:before {
    position: relative;
    z-index: 5;
    content: "\f0c8";
    font-family: 'Font Awesome\ 5 Free';
    font-weight: normal;
    width: 20px;
    display: inline-block;
    cursor: pointer;
    color: #333;
}

input[type="checkbox"]:checked + label:before {
    position: relative;
    z-index: 5;
    content: "\f14a";
}

input[type="checkbox"]:indeterminate + label:before {
    position: relative;
    z-index: 5;
    content: "\f0c8";
    color: grey;
}

input[type="radio"]+label:before {
    position: relative;
    z-index: 5;
    content: "\f111";
    font-family: 'Font Awesome\ 5 Free';
    font-weight: normal;
    width: 20px;
    display: inline-block;
    cursor: pointer;
}

input[type="radio"]:checked + label:before {
    position: relative;
    z-index: 5;
    content: "\f058";
}

input[type="radio"]:indeterminate + label:before {
    position: relative;
    z-index: 5;
    content: "\f111";
    color: grey;
}

input[type="checkbox"]:disabled + label,
input[type="radio"]:disabled + label {
    cursor: default!important;
    color: #a9a9a9;

    &:before {
        cursor: default!important;
        color: #999;
    }
}

// <input type="checkbox" name="stripe" id="stripe" value="1" checked="checked" class="checkbox-switch" />
// <label for="stripe"></label>
input[type="checkbox"].checkbox-switch {
    display: none;

    & + label {
        box-sizing: border-box;
        display: inline-block;
        width: 3rem;
        height: 1.5rem;
        border-radius: 1.5rem;
        padding: 2px;
        background-color: #c0ceda;
        transition: all 0.2s;
        color: lighten($text-color, 25%);

        &::before {
            box-sizing: border-box;
            display: block;
            content: "";
            height: calc(1.5rem - 4px);
            width: calc(1.5rem - 4px);
            border-radius: 50%;
            background-color: #fff;
            transition: all 0.2s;
        }
    }

    &:checked + label {
        color: $text-color;
        background-color: $primary-dark;

        &::before {
            margin-left: 1.5rem;
        }
    }

    &:disabled + label {
        background-color: lighten($primary, 25%);
        cursor: not-allowed;
    }
}
