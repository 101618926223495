@import 'registration-breadcrumb';

.inscriptions {
    .card {
        .card-body.row {
            .col-md-6.with-border {
                border-bottom: solid 1px #ddd;
                padding-bottom: 2em;

                @media (min-width: 768px) {
                    border-bottom: none;
                    padding-bottom: 0;
                    border-right: solid 1px #ddd;
                    padding-right: 1.5em;
                }
            }

            .col-md-6.with-border + .col-md-6 {
                @media (min-width: 768px) {
                    padding-left: 1.5em;
                }
            }
        }
    }
}

.registration-breadcrumb {
    margin: 0 0 0 5px;
    display: inline-flex;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 12px;
    background: rgba(245, 245, 245, .5);

    > li + li:before {
        // content: "\F105";
        // font-family: 'Font Awesome\ 5 Free';
        // color: #ccc;
        // padding: 0 5px;
    }

    //li:first-child:before { content: "\f103"; }

    li a {
        display: inline-block;
        margin-top: 0.15em;
        color: $primary;
    }
}

.registration-box {

    //    padding-top: 60px;
    // > div > div {
    //     box-shadow: 0 1px 1px 0 rgba(0,0,0, 0.14), 0 2px 1px -2px rgba(0,0,0, 0.2), 0 1px 4px 0 rgba(0,0,0, 0.12);
    // }
    .registration-header {
        margin: 0;
        padding: 20px 15px 20px;
        border: 1px solid #eee;
        border-bottom: 0;
        border-right: 1px solid rgba(221, 221, 221, .7);

        h1 {
            margin-top: 0;
            color: #555;
            //font-size: 20px;
        }

        .price {
            // float: right;
            font-size: 1.4em;

            .text-highlight {
                font-size: 1.8em;
                line-height: 1.1em;
                font-family: 'Arial', sans-serif;
            }
        }

        p:last-child {
            // float:left;
        }
    }

    .registration-body {

        @media (max-width:$sm) {
            //padding: 25px;
            padding: 20px 35px 35px;
        }

        //        padding: 25px 25px 20px 25px;
        @media(min-width:$lg) {
            background: #fff;
            border: 1px solid #eee; // box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.1);
            border-top: 1px solid #ddd;
        }


        .choix-facturation input[type="radio"]:checked + label {}

        .legit-info {
            line-height: 1.4;
        }

        .input-group-text {
            border-radius: 0;
        }

        button[type="submit"]:not([disabled]),
        label {
            cursor: pointer;
            appearance: none;
            box-sizing: border-box;
        }

        label {
            @media (max-width:$lg) {
                //margin-bottom: 1em;
                padding-bottom: 0;
            }
        }
    }
}

.rub-promo {
    background: #FFD700;
    background-image: -ms-radial-gradient(center top, circle farthest-side, #FFF2B5 0%, #FFD700 100%);
    background-image: -moz-radial-gradient(center top, circle farthest-side, #FFF2B5 0%, #FFD700 100%);
    background-image: -o-radial-gradient(center top, circle farthest-side, #FFF2B5 0%, #FFD700 100%);
    background-image: -webkit-gradient(radial, center top, 0, center top, 487, color-stop(0, #FFF2B5), color-stop(1, #FFD700));
    background-image: -webkit-radial-gradient(center top, circle farthest-side, #FFF2B5 0%, #FFD700 100%);
    background-image: radial-gradient(circle farthest-side at center top, #FFF2B5 0%, #FFD700 100%);
    width: 60px;
    height: 3em;
    padding-top: 1em;
    position: absolute;
    z-index: 999;
    border-top-right-radius: .2em;
    border-top-left-radius: .2em;
    margin: auto;
    margin-left: 90px;
    margin-top: -20px;
    /*right: 10px;*/
    text-align: center;
    font-weight: bold;
    display: inline-block;
    text-shadow: 1px 1px 0 #FFF;
    box-shadow: 0 4px 6px #E0E0E0;

    &:before {
        content: "";
        position: absolute;
        bottom: -.4em;
        left: 0;
        width: 0;
        height: 0;
        border-top: .5em solid #FFD700;
        border-right: 2.5em solid transparent;
    }

    &:after {
        content: "";
        position: absolute;
        bottom: -.4em;
        right: 0;
        width: 0;
        height: 0;
        border-top: .5em solid #FFD700;
        border-left: 2.5em solid transparent;
    }
}
