html {
   // height: 100%;
   // background-color: #fff !important;

    body {
        //        height: 100%;
        //        min-height: 100%;
        //        background-color: #fff;

        a {
            color: $a-color-primary;

            &.disabled {
                cursor: not-allowed;
            }
        }

        .b,
        b {
            font-weight: 700;
        }

        table {
            th {
                font-weight: 600;
            }
        }
    }
}

.line1 {
    line-height: 1.2em;
}

//.section-login {
//    overflow: hidden;
//
//    @media(min-width:$md) {
//        background: url("/images/_assets/page-code/end-page.svg") no-repeat scroll center bottom;
//        background-size: contain;
//    }
//
//    &:before {
//        @media(min-width:$md) {
//            content: '';
//            display: block;
//            width: 160px;
//            height: 120px;
//            background: url("/images/_assets/page-code/voiture-conducteur.svg") no-repeat scroll 0 0;
//            background-size: contain;
//            position: absolute;
//            z-index: 2;
//            bottom: 0;
//            left: -40vw;
//            animation: anim-end 10s linear infinite;
//        }
//
//        @media(min-width:$lg) {
//            width: 320px;
//            height: 240px;
//        }
//    }
//}

@keyframes anim-end {
    from {
        left: -40vw;
    }

    to {
        left: 130vw;
    }
}

.overflow-hidden {
    overflow: hidden;
}

//redefs des backgrounds, background-gradients, alert, ... de bootstrap aux couleurs PE
//redef de boutons btn-outline-white et text-[color] combinés, ex: btn-outline-white.text-primary
$brand-backgrounds: ('primary': $primary,
    'primary-hover': $primary-dark,
    'info': $info,
    'success': lighten($success, 5%),
    'warning': $warning,
    'teal': $teal,
    'pink': $pink,
    'danger': $danger,
    'dark': $dark,
    'black': $black, //'purple': $purple,
    //'orange': $orange,
    'grey': $grey);

@each $brand-bkg,
$set in $brand-backgrounds {
//    .btn-outline-white.text-#{$brand-bkg} {
//        color: $white !important;
//
//        &:hover {
//            color: $set;
//        }
//    }

//    .bg-#{$brand-bkg} {
//        //background-color: $set !important;
//        color: $white;
//        //.lead { color: $white !important; }
//    }

    //    .bg-#{$brand-bkg}-gradient {
    //        background: linear-gradient(135deg, darken($set, 10%) 0%, lighten($set, 5%) 100%);
    //        color: $white;
    //    }
    //
    //    .bg-#{$brand-bkg}-slice {
    //        background: linear-gradient(125deg, lighten($set, 50%), lighten($set, 50%) 50%, lighten($set, 37%) 70%, lighten($set, 37%));
    //        box-shadow: inset 0 0 1em 0.5em lighten($set, 37%);
    //    }
}


.bg-none {
    background: none !important;
}

.bg-transparent {
    background-color: transparent;
}

.box-shadow {
    box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.08);
}
.box-shadow-hard {
    box-shadow: 0 6px 10px -3px rgba(0, 0, 0, 0.2); 
}

// passage n&b
.desaturate {
    filter: saturate(0) brightness(1.5);
}
.grayscale {
    filter: grayscale(1);
}

// plus lumineux
.brightness {
    filter: brightness(1.5);
}
// invertion noir-blanc + préserve les couleurs:
.invert {
    filter: invert(100%) hue-rotate(180deg) brightness(127%);
}
// multiply over anything : les tons clairs prennent la couleur du fond
.multiply {
    mix-blend-mode: multiply;
}
// lighten over anything : Les tons foncés prennent la couleur du fond
.lighten {
    mix-blend-mode: lighten;
}



.zoom-hover {
    transition: all 200ms ease-in-out;
}

.zoom-hover:hover {
    transform: scale(1.2);
}

.zoom-x2-hover {
    transition: all 200ms ease-in-out;
}

.zoom-x2-hover:hover {
    transform: scale(2);
}

@media(min-width:$lg) {
    //jeu de taille xtra large pour gros titres
    $i: 1;
    $fact: 1.2;

    @while $i < 5 {
        .hx#{$i} {
            font-size: ($h1-font-size * $fact);
        }

        $i: $i + 1;
        $fact: $fact * $fact - $fact/16;
    }
}


picture {
    display: block;
    max-width: 400px;
    margin: 0 auto;

    &.sm {
        max-width: 300px;
    }

    &.xs {
        max-width: 240px;
    }
}

@media(min-width:$lg) {

    picture,
    picture.sx,
    picture.sm {
        //display: inline;
        max-width: auto;
        margin: initial;
    }
}


.img-contain {
    object-fit: contain;
}
.img-cover {
    object-fit: cover;
}
.img-contain,
.img-cover {

    //max-height: 320px;
    //height: auto !important;
    &.bottom {
        object-position: bottom;
    }

    &.center {
        object-position: center;
    }

    &.top {
        object-position: top;
    }
}





// container XL = max-width 1440px -> rompre un peu avec la grille
.container.xl {
    max-width: 1440px;
}

.w-sm {
    max-width: $sm;
}

.w-md {
    max-width: $md;
}

.w-lg {
    max-width: $lg;
}

.w-xl {
    max-width: $xl;
}

.pointer {
    cursor: pointer;
}

.h1,
h1 {
    font-size: $h1-font-size;
}

.h2,
h2 {
    font-size: $h2-font-size;
}

.h3,
h3 {
    font-size: $h3-font-size;
}

.h4,
h4 {
    font-size: $h4-font-size;
}

.h5,
h5 {
    font-size: $h5-font-size;
}

.h6,
h6 {
    font-size: $h6-font-size;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    // clear: both;
    margin: 0.25em 0;
    font-weight: 700;
}

h1 {
    small {
        display: block;
    }

    &.upside {
        display: flex;
        flex-direction: column-reverse;
    }
}

a,
a[role="button"] {
    cursor: pointer;
}

a {
    &.arrow-right {
        display: inline-block;
        position: relative;
        padding-right: 12px;

        &::after,
        &::before {
            border-right: 2px solid;
            content: '';
            display: block;
            height: 7px;
            margin-top: -6px;
            position: absolute;
            transform: rotate(135deg);
            right: 2px;
            top: 50%;
            width: 0;
        }

        &::after {
            margin-top: -2px;
            transform: rotate(45deg);
        }
    }
}

code {
    font-size: 87.5%;
    color: #e83e8c;
    word-break: break-word;
    // &.pre {
    //     white-space: pre;
    // }
}

#app-dv code.pre {
    white-space: pre;
}

.fs-large {
    font-size: large;
}

.fs-medium {
    font-size: medium;
}

.fs-small {
    font-size: small;
}

.fs-smaller {
    font-size: smaller;
}

hr {
    &.dashed {
        border-top: 1px dashed #eee;
    }

    &.dotted {
        border-top: 1px dotted #eee;
    }
}

/*.db {
    display: block;
}

.dib {
    display: inline-block;
}*/

//.fl-right {
//    float: right !important;
//}

.nobr {
    white-space: nowrap;
}

// @Pascal 2018-05-12 C'est pourquoi cette règle ? Pourquoi .btn-primary et pas .btn ?
// .vam .nobr .btn-primary { padding: 3px 8px 4px; }

.vat {
    vertical-align: top;
}

.vam {
    vertical-align: middle;
}

.vab {
    vertical-align: bottom;
}

//.tac {
//    text-align: center;
//
//    * {
//        text-align: center;
//    }
//}
//
//.tar {
//    text-align: right;
//
//    * {
//        text-align: right;
//    }
//}
//
//.tal {
//    text-align: left;
//
//    * {
//        text-align: left;
//    }
//}

//.m0 {
//    margin: 0 !important;
//}
//
//$paddings: 0,
//2,
//3,
//5,
//7,
//10,
//15,
//20;
//
//@each $i in $paddings {
//    .mt#{$i} {
//        margin-top: #{$i}px !important;
//    }
//}
//
//.mtl10 {
//    margin-top: -10px;
//}
//
//// .mtl20 {
////     margin-top: -20px;
//// }
//@each $i in $paddings {
//    .mr#{$i} {
//        margin-right: #{$i}px;
//    }
//}
//
//// .mrl15 {
////     margin-right: -15px;
//// }
////
//// .mrl30 {
////     margin-right: -30px;
//// }
//@each $i in $paddings {
//    .ml#{$i} {
//        margin-left: #{$i}px !important;
//    }
//}
//
//.mll5 {
//    margin-left: -5px;
//}
//
//@each $i in $paddings {
//    .mb#{$i} {
//        margin-bottom: #{$i}px;
//    }
//}
//
//@each $i in $paddings {
//    .p#{$i} {
//        padding: #{$i}px !important;
//    }
//}
//
//@each $i in $paddings {
//    .pt#{$i} {
//        padding-top: #{$i}px;
//    }
//}
//
//@each $i in $paddings {
//    .pl#{$i} {
//        padding-left: #{$i}px;
//    }
//}
//
//@each $i in $paddings {
//    .pr#{$i} {
//        padding-right: #{$i}px !important;
//    }
//}

/*.pr25 {
    padding-right: 25px;
}
*/

//
//.pr50 {
//    padding-right: 50px;
//}
//
//@each $i in $paddings {
//    .pb#{$i} {
//        padding-bottom: #{$i}px;
//    }
//}
//
//.bt0 {
//    border-top: 0;
//    border-top-right-radius: 0;
//    border-top-left-radius: 0;
//}
//
//.br0 {
//    border-right: 0;
//    border-top-right-radius: 0;
//    border-bottom-right-radius: 0;
//}
//
//.bl0 {
//    border-left: 0;
//    border-top-left-radius: 0;
//    border-bottom-left-radius: 0;
//}

img {
    // width: 100%; /* or max-width: 100%; */
    // height: auto;
    object-fit: cover;
    aspect-ratio: attr(width) / attr(height);
}

.img-avatar {
    display: inline-block;
    position: relative;
    margin: 0 -5px 5px !important;
    width: 60px;
    height: 60px;
    border: 1px solid #ddd;
    border-radius: 50%;
    overflow: hidden;
    float: left;
    transition: all 0.2s;

    // transform: scale(0.9);
    // transition: transform 0.3s, border-width 0.3s;
    // &:hover {
    //     border-width: 2px;
    //     transform: scale(1.1);
    // }
    &:hover:not(.no-img-hover) {
        z-index: 999;
        transform: scale(1.6);

        //width: 96px;
        //height: 96px;
        //margin: -13px 10px 5px -13px !important;
        > div {
            //border-width: 3px;
        }
    }

    > div {
        border: 2px solid #fff;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        background: #eee;
        overflow: hidden;

        img {
            width: 100%;
            max-width: 100%;
            object-fit: cover;
            height: 100%;
        }
    }

    &.img-avatar-x05 {
        width: 36px;
        height: 36px;
        margin: 0 10px 10px 0 !important;
        border: 1px solid transparent;

        &:hover:not(.no-img-hover) {
            width: 36px;
            height: 36px;
            margin: 0 10px 5px 0 !important;
        }

        > div {
            background: transparent;
            border: 1px solid transparent;
        }
    }

    &.img-avatar-x2 {
        width: 96px;
        height: 96px;

        > div {
            border-width: 3px;
        }
    }

    > div {
        &.bg-admin {
            background: gold;
        }
    }
}

.border-inset {
    border: 1px solid #eee;
    box-shadow: 0 0 1px #ccc inset;
}

//.text-success {
//    color: #2ab27b;
//}

//.text-warning {
//    color: darkorange;
//}

//.text-bigger {
//    font-size: 1.2em;
//}

.text-highlight {
    //color: #8BC34A;
    color: $primary;
}

// .fa, .fab, .fal, .far, .fas {
//     display: inline-block;
//     width: 1.25em;
// }

.text-loading {
    color: #31708f;
    font-size: 16px;
    padding-left: 22px;

    .fa,
    .fab,
    .fal,
    .far,
    .fas {
        margin-top: 4px;
        font-size: 14px;
        position: absolute;
        margin-left: -22px;
    }
}



//.text-pink { color: #e91e63; }
.text-muted {
    margin-top: 0.25rem;
    font-size: 0.875rem;
}

//.text-secondary {
//    color: $gray-600 !important;
//}

// .fa, .fab, .far, .fas {&
.fa-fw {
    margin-right: 2px !important;
}

// }
.fa-sort {
    cursor: move;
    /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.tree-area {
    padding: 10px;
    min-height: 100px;
    border: 1px solid #ccc;

    &:hover {
        border: 1px dotted green;
    }
}

.box-ellipsis {
    position: relative;
    max-width: 100%;
    padding-right: 15px;

    .text-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.line-ellipsis {
    height: 14px;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.drop-table {
    display: table;
    width: 100%;
    min-height: 300px;
    white-space: nowrap;

    .drop-table-left,
    .drop-table-right {
        display: inline-block;
        width: 50%;
        min-height: 300px;
        max-height: 450px;
        overflow-y: scroll;
        padding: 10px 2px 10px 7px;

        tr td {
            // .drop-table .drop-table-right tr td
            padding: 0.60rem 0.75rem 0;
        }

        .fa-sort {
            // -ms-transform: rotate(90deg);
            // -webkit-transform: rotate(90deg);
            // transform: rotate(90deg);
        }

        .box-ellipsis {
            position: relative;
            max-width: 217px;
            padding-right: 15px;

            .text-ellipsis {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        & > div {
            // margin: 0 0 4px;
            padding: 7px 0;

            &:hover {
                background-color: rgba(241, 241, 241, 0.7);
            }

            &:active {
                background-color: rgba(241, 241, 241, 1);
            }

            > table {
                margin-top: -7px;
            }

            label {
                margin: 0;
            }

            select.form-control {
                height: 23px;
                padding: 0 0 0 2px;
                box-shadow: initial;
                border-color: rgba(204, 208, 210, .4);
            }
        }
    }

    .drop-table-right {
        border-left: 0;
        margin-left: -3px;
    }

    .drop-table-left {
        border-right: 0;
    }

    &.drop-table-small {
        min-height: 100px;

        .drop-table-left,
        .drop-table-right {
            min-height: 200px;
            max-height: 450px;
        }

        .btn-sm {
            border-width: 1px;
            padding: 2px 6px 1px;
            height: 22px;
            line-height: 1.3;
        }
    }

    &.drop-table-wild {

        .drop-table-left,
        .drop-table-right {
            width: 100%;
        }
    }
}

.dropdown-dd {
    position: absolute;
}

/*.card {
    display: block;
    padding: 20px;
    margin: 0 0 10px;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 2px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}*/
.accordion {
    .card {
        margin: 0 0 -2px;
        padding: 0;

        &:last-of-type {
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        }

        .card-header {
            background: rgba(0, 0, 0, 0.01);

            a {
                text-decoration: none;
                color: #212529;
                font-size: 1.1rem;
            }
        }

        .card-body {
            a:hover {
                color: #3b78e7;
            }
        }
    }
}

@media (max-width: $md) {
    .card-header {
        border-radius: 0 !important;
    }
}

pre.xdebug-var-dump {
    padding: 15px;
    font-size: 12px;
    border: 1px solid #F8F8F8;
    background-color: rgba(245, 245, 245, .4);
}

pre.sf-dump,
pre.sf-dump .sf-dump-default {
    background-color: #18171B;
    color: #FF8400;
    line-height: 1.2em;
    font: 12px Menlo, Monaco, Consolas, monospace;
    word-wrap: break-word;
    white-space: pre-wrap;
    position: relative;
    z-index: 99999;
    word-break: break-all;
}

pre.sf-dump {
    display: block;
    white-space: pre;
    padding: 5px;
}

//.bg-light-gray-gradient {
//    background: linear-gradient(135deg, #fff 0%, #F8F8F8 100%);
//    background-color: rgba(0, 0, 0, 0);
//    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff', endColorstr='#F8F8F8', GradientType=1);
//    transition: all 0.9s;
//}

.badge {
    padding: 0.5em 0.8em 0.3em 0.7em;
}

.video-player-embedded {
    // background: purple; padding: 5px;

    .video-js {
        // background: red; padding: 5px;
        max-width: 100%;
    }
}

/* Transitions */
/* always present */
.expand-transition,
.modal-transition {
    transition: all 0.3s ease;
    height: 30px;
    padding: 10px;
    background-color: #eee;
    overflow: hidden;
}

/* .expand-enter defines the starting state for entering */
/* .expand-leave defines the ending state for leaving */
.expand-enter,
.expand-leave,
.modal-enter,
.modal-leave {
    height: 0;
    padding: 0 10px;
    opacity: 0;
}

// Fade
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}

/* .fade-leave-active in <2.1.8 */
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

// Slide-fade
.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: transform 0.2s ease, opacity 0.2s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateX(5px);
    opacity: 0;
    position: absolute;
    float: left;
}

//// Bounce
//.bounce {
//    display: inline-block;
//    animation: bounce 3s ease infinite;
//}
//
//@keyframes bounce {
//
//    60%,
//    68%,
//    81.5%,
//    91.5%,
//    from,
//    to {
//        transform: translate3d(0, 0, 0);
//    }
//
//    75%,
//    77.5% {
//        transform: translate3d(0, -30px, 0);
//    }
//
//    87.5% {
//        transform: translate3d(0, -15px, 0);
//    }
//
//    95.5% {
//        transform: translate3d(0, -4px, 0);
//    }
//}

#rgpd {
    // display: none;
    position: fixed;
    left: 0;
    bottom: 0;
    background: #fff;
    border-top: 1px solid #ccc;
    padding: 30px 20px 40px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, .1) 2px 2px 14px 2px;
    z-index: 1000;
}

#addblock {
    // display: none;
    position: fixed;
    left: 0;
    bottom: 0;
    background: #fff;
    border-top: 1px solid #ccc;
    padding: 30px 20px 40px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, .1) 2px 2px 14px 2px;
    z-index: 999;
}
