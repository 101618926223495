/* TinyMce */
.tox-toolbar__group:nth-child(2) {
    max-width: 100px;
    overflow: hidden;
}
/* test typo : Oxygen */
.btn {
    font-weight: 600;
    font-size: 15px;
    //font-family: "Roboto",Helvetica,Arial,sans-serif;
    font-family: 'Arial', sans-serif;
    outline: none;
    border-radius: 0.3em;
    transition: box-shadow 0.2s cubic-bezier(.4,0,1,1),background-color 0.2s cubic-bezier(.4,0,.2,1),color 0.2s cubic-bezier(.4,0,.2,1);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.12);
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.12);
    }

    &.btn-text:hover {
        opacity: 0.9;
        box-shadow: none;
    }

    &.btn-sm {
        &:active {
            padding: 6px 10px 4px;
        }
    }

    &.btn-lg {
        padding: 10px 16px;
        font-size: 18px;
    }

    &.btn-xl {
        padding: 16px 24px;
        font-size: 22px;
    }

    &.disabled,
    &[disabled] {
        cursor: not-allowed;
    }
}

.btn-danger,
.btn-dark,
.btn-info,
.btn-light,
.btn-primary,
.btn-secondary,
.btn-success,
.btn-warning {
    &:hover {
//         filter: brightness(1.15) saturate(1.15);
    }
}

.btn-primary {
    color: #fff !important;
    background-color: darken($primary, 5%);

    &:hover {
        background-color: $primary;
        border-color: lighten($primary, 5%);
        box-shadow: 0 3px .8em 0 lighten($primary, 22%);
    }
}

.btn-light {
    &:hover {
        background-color: lighten($light, 3%);
        border-color: lighten($light, 1%);
    }
}

.btn-secondary {
    color: #555;
    background-color: lighten($secondary, 9%);
    border-color: #cccccc;

    &:hover {
        color: #222;
        background-color: #fff;
        // border-color: #c8c8c8;
        //border-color: #bbbbbb;
        //box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.12);
    }
}

.btn-danger {
    font-size: 13.3px;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff !important;
    background-color: $danger;
    background-image: -moz-linear-gradient(to top,#dd4b39,#d14836);
    background-image: linear-gradient(to top,#dd4b39,#d14836);
    background-image: -moz-linear-gradient(to bottom,#dd4b39,#d14836);
    background-image: linear-gradient(to bottom,#dd4b39,#d14836);
    border: 1px solid transparent;

    &:focus {
        border: rgba(0,0,0,0) solid 1px;
        outline: rgba(0,0,0,0) 0;
        box-shadow: inset 0 0 0 1px #fff;
    }

    &:active {
        background-color: #b0281a;
        background-image: -moz-linear-gradient(to top,#dd4b39,#b0281a);
        background-image: linear-gradient(to top,#dd4b39,#b0281a);
        border: 1px solid #992a1b;
        box-shadow: inset 0 1px 2px rgba(0,0,0,0.3);
    }
}

.btn-success {
    color: #fff !important;

    &:hover {
        background-color: lighten($success, 3%);
        border-color: lighten($success, 3%);
    }
}
.btn-teal {
    background-color: darken($teal, 3%);
    &:hover {
        background-color: $teal;
        border-color: lighten($teal, 3%);
    }
}
.btn-pink {

    background-color: darken($pink, 3%);

    &:hover {
        background-color: $pink;
        border-color: $pink;
    }
}

.btn-pay:before {
    text-align: center;
}

.btn-pay.hover:before,
.btn-pay:hover:before {
    margin-right: 8px !important;
    width: 1.25em;
    font-family: "Font Awesome 5 Free";
    content: "\f07a";
}

.btn-link,
.btn-text,
.btn-text:active {
    text-transform: uppercase;
    font-size: 0.9em;
    font-weight: 600;
    padding: 6px 12px;
    box-shadow: initial;
    background: transparent;
    border: initial;
    color: $primary-dark !important;
    cursor: pointer;
    outline: none;
    transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {}

    &:active {}
}

.btn-link {
    font-size: 1em;
    text-transform: initial;
    box-shadow: initial;
}

.btn-small {
    padding: 5px 9px 4px;
    font-size: 0.9em;
    font-weight: 500;
}

.btn-twitter {
    color: #fff;
    background-color: #55acee;

    &:hover {
        color: #fff;
        background-color: #2795e9;
        border-color: rgba(0,0,0,0.2);
    }
}

.btn-facebook {
    color: #fff;
    background-color: #3b5998;

    &:hover {
        background-color: #2d4373;
    }
}

.btn-google {
    color: #fff;
    background-color: #dd4b39;

    &:hover {
        color: #fff;
        background-color: #c23321;
    }
}

.btn-social {
    position: relative;
    padding: 8px 12px 8px 48px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: initial !important;
    font-weight: initial !important;
    font-size: 1em !important;
    width: 100%;

    > :first-child {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 37px;
        line-height: 37px;
        font-size: 1em;
        text-align: center;
        border-right: 1px solid rgba(0,0,0,0.2);
    }

    &:focus,
    &:hover {
        color: #fff;
    }
}

.btn-clipboard {
    margin-left: 5px;

    &:active {
        color: red;
        opacity: 0.7;

        i {
            transform: rotate(8deg);
            zoom: 90%;
        }
    }
}
