//reboot colors for bootstrap
$spacer: 1rem !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: hsl(210, 14%, 89%) !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

$blue:    #3a98f6 !default;
//$indigo:  #3152dd !default;
$purple:  #8442c1 !default;
$pink:    #e83e8c !default;
$red:     #ed3b3b !default;
$orange:  #ff581b !default;
$yellow:  #ffc107 !default;
$green:   #1db167 !default;
$teal:    #20c997 !default;
$teal-light : lighten($teal, 15%)!default;
$cyan:    #54caf5 !default;

$primary:       $blue !default;
$primary-dark:  #1584f2;
$primary-light: lighten($primary, 20%)!default;
$secondary:     $gray-300 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-200 !default;
$grey:          $gray-600 !default;
$dark:          $gray-800 !default;
$black:         #000000 !default;
$white:         #ffffff !default;

//ajout de color-sets
$theme-colors: (
    "teal":     $teal,
    "pink":     $pink, 
//    "orange":   $orange,
//    "purple":   $purple,
    "grey":     $grey,
    "black":    $black,
    "white":    $white,
    "primary-light": $primary-light,
    "primary-dark": $primary-dark,
    "teal-light": $teal-light
);

//rajout de paddings
$spacers: (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
    6: ($spacer * 4.5),
    7: ($spacer * 7.5),
    8: ($spacer * 12)
);

// breakpoints
$xs: 320px; $xsw: 319px;
$sm: 576px; $smw: 575px;
$md: 768px; $mdw: 767px;
$lg: 992px; $lgw: 991px;
$xl: 1200px; $xlw: 1199px;
