.testimonials {
    // background: #F8F8F8;
    // margin: 50px 0 0;
    // padding: 20px 0;

    .container {
        //margin: 24px auto;
    }
    
    /*.progress {
        
        .progress-bar {
            width: attr(aria-valuenow); 
        }
        
    }*/

    .testimonial {
        // display: inline-block;
        //padding-right: 20px;
        //padding-bottom: 22px;
        // width: 33%;
        // min-width: 200px;
        // vertical-align: bottom;

        blockquote {
            display: inline-block;
            //max-height: 6rem;
            //overflow: auto;
            //background-color: #fff;
            //border-radius: 6px;
            font-family: Georgia, serif;
            font-size: 16px;
            line-height: 1.4;
            margin: 0;
            //padding-right: 10px;
            //padding: 1rem;
            //border: 1px solid #ccc;
            //box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.14);
        }

        .testimonial-text {
            color: lighten($text-color, 10%);
        }

        .testimonialArrow {
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #ccc;
            margin-top: -9px;
            margin-left: 22px;
        }

        .img-avatar {
            margin: -7px 10px 0 0 !important;
        }

        .testimonial-text {
            border-top: dotted 2px $border-color;
        }

        strong {
            color: rgb(68, 68, 68);
        }

        a {
            color: $primary;
            text-decoration: none;
        }

        .response {
            background: rgba(0, 0, 0, 0.02);
            box-shadow: inset 0px 15px 5px -15px rgba(0, 0, 0, 0.2);

            .ico-reply {
                position: absolute;
                left: 1em;
                top: 1.1em;
                display: block;
                width: 20px;
                height: 20px;
                opacity: .5;
                background-size: contain;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0OCA0OCI+PHBhdGggZD0iTTMwLjEyNSw0Mi4zOWExLjg5MSwxLjg5MSwwLDAsMS0xLjQ4OS41MSwyLjIsMi4yLDAsMCwxLTEuMzYyLS42MzcsMS45MDYsMS45MDYsMCwwLDEtLjU1Mi0xLjQwNVYzNC4wNTJRMTUuMDY2LDMzLjg4MSw5LjM2NSwzMC45LDIuMjE3LDI3LjE2MSwyLjIxOCwxOC4yMjdhMTUuMDQxLDE1LjA0MSwwLDAsMSwyLjEyNy03LjQ4OEExNi45NjUsMTYuOTY1LDAsMCwxLDkuMjgsNS4xMjRhMS41MzUsMS41MzUsMCwwLDEsMS4yMzMtLjMsMS42MzIsMS42MzIsMCwwLDEsMS4wMjEuNjM4LDEuMTc4LDEuMTc4LDAsMCwxLC4xMjgsMS4xOTFROS43OSwxMi43LDEwLjksMTYuMTQyYTcuNjkyLDcuNjkyLDAsMCwwLDUuMTksNC44OTJxMy42NTksMS4zNjEsMTAuNjM2LDEuNDQ2VjE0Ljk5M2ExLjkwNSwxLjkwNSwwLDAsMSwuNTUyLTEuNCwyLjIsMi4yLDAsMCwxLDEuMzYyLS42MzgsMS44OTIsMS44OTIsMCwwLDEsMS40ODkuNTExTDQ1LjEsMjYuNGEyLjA2NCwyLjA2NCwwLDAsMSwwLDMuMDYyWiIvPjwvc3ZnPg==");
            }
        }

    }
}

.text-gold {
    color: #ec971f !important;
}

.text-gold-light {
    color: rgba(236, 151, 31, .8) !important;
}
