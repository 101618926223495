.collapse-text {
    p.collapse {
        height: 42px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        &.show {
            height: auto;
            overflow: auto;
            -webkit-box-orient: initial;
        }
    }

    p.collapsing {
        min-height: 42px !important;
    }

    a {
        &.collapsed:after {
            content: '+ Plus';
        }

        &:not(.collapsed):after {
            content: '- Moins';
        }
    }
}
