/* Table */
.table-inverse {
    color: #fff;
    background-color: #292b2c !important;
}

.table-inverse td,
.table-inverse th,
.table-inverse thead th {
    border-color: #fff;
}

.table-striped {
    margin-bottom: 1px;

    tbody {
        > tr:nth-of-type(2n+1) {
            background-color: rgba(249, 249, 249, 0.4);
        }

        > tr:first-child {
            td {
                border-top: 0;
            }
        }

        td,
        th {
            padding: 12px 15px 8px !important;
        }

        td {
            border-top: 1px solid #e9e9e9 !important;
            vertical-align: middle !important;
        }
    }

    &.table-sm {
        td,
        th {
            padding: 8px 15px 6px !important;
        }
    }
}

.table {
    .table-md {
        td,
        th {
            padding: 0.60rem 0.70rem !important;
        }
    }
}

/* Tables row/col */
.row {
    &.row-eq-height {
        display: flex;
        flex-wrap: wrap;

        & > [class*='col-'] {
            // display: flex;
            display: block;
            flex-direction: column;
            align-items: center;
            align-self: flex-end;
        }
    }
}
