.plan-detail {
    // display: -none;
    //background: #fff;
    //padding: 10px 35px 20px 25px;
    //border: 1px solid #eee;
}

.plan-detail ul li {
    .counter {
        position: absolute;
        top: -.6em;
        right: 0;
        font-weight: bolder;
        padding: 0.6em 0.6em 0.3em 0.66em;
        @media (min-width: 992px) {
            font-size: 1rem;
            padding: 0.5em 0.6em 0.3em 0.66em;
        }
    }

    .description {
        font-size: 0.87em;
        line-height: 1.3;
    }
}

body.customer {
    .plan-detail {
        h1,h2,h3,h4,h5,h6 {
            font-family: 'Arial', sans-serif;
        }

        h1 {
            font-size: 38px;
        }

        h3 {
            font-size: 16px;
            margin-bottom: .5em;
        }

        .description {
            font-family: 'Martel sans', sans-serif;
            font-size: 12.8px;
        }
    }
}
