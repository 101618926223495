@import "~vue-select/src/scss/vue-select";

.v-select {
    height: $input-height;

    .vs__dropdown-toggle {
        height: $input-height;
    }

    input[type=search] {
        font-size: $font-size-base !important;
    }

    & > .vs__dropdown-toggle {
        border-radius: 0 !important;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

        .vs__selected {
            font-size: $font-size-base;
            border-radius: 0;
            padding: 0 0.45em 1.2px;
        }
    }

    &.without-tag {
        & > .vs__dropdown-toggle {
            .vs__selected {
                padding-right: 0.7em;
                border-color: transparent;
                border-right-color: rgba(60,60,60,.10);
                background: transparent;
            }
        }
    }
}
