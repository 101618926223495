// timer
$timer_radius: 60px;
$timer_width: 4px;
$timer_speed: 1s;
$bkg: $primary;

.timer {
    display: inline-block;
    width: $timer_radius;
    height: $timer_radius;
    border-radius: 50%;
    padding: 3px;
    
    background-color: $bkg;
    
    font-size: 0;
    position: relative;

    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-size: 80%;
        background-attachment: scroll;
        background-position: center center;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiI+PHRpdGxlPnlvcDwvdGl0bGU+PHBvbHlnb24gcG9pbnRzPSI5LjM3IDI1Ljk0IDExLjkgMjUuOTQgMTMuNTYgMTguNjMgMTEuMDMgMTguNjMgOS4zNyAyNS45NCIgZmlsbD0iI2ZmZiIvPjxwYXRoIGQ9Ik0xOC41LDZIMTYuMzdBMi45NCwyLjk0LDAsMCwwLDEzLjQ0LDhsLTEuMyw1LjcyaDIuNDRsLjczLTMuMjRhMi42OSwyLjY5LDAsMCwxLDIuNTUtMi4zNGguMzJjMi41OSwwLDMuNjgsMS4yNiwzLDQtLjQ5LDItMi4xLDIuODgtNC40NywyLjg4SDEwLjE1YS40OS40OSwwLDAsMC0uNDkuNDFsLS4yOCwxLjQ0YS4zNC4zNCwwLDAsMCwwLC4xLjUuNSwwLDAsMCwuNS41aDYuNjljMy42OSwwLDYuMzEtMS41Myw3LjA2LTQuOTJDMjQuNSw4LjcyLDIyLjc4LDYsMTguNSw2WiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==);
    }

    &:after {
        position: absolute;
        top: 0;bottom: 0;
        left: 0;right: 0;
        
        margin: 2px;
        
        content: '';
        display: block;
        border-radius: 50%;

        border-top: solid $timer_width transparent;
        border-left: solid $timer_width transparent;
        border-bottom: solid $timer_width transparent;
        border-right: solid $timer_width white;

        animation: TimerFrames linear $timer_speed infinite;

    }

}

@keyframes TimerFrames {

    0%,
    100% {
        animation-timing-function: cubic-bezier(0.5, 0.2, 1, 0.5);
    }

    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
