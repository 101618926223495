article {}

.toc {
    border: 1px solid #F8F8F8;
    background: rgba(247, 247, 247, .5);
    padding: 10px 25px;
    margin-left: 20px;
    float: right; // clear: right;
    max-width: 40%;
    font-size: 13px;

    @media (max-width: $mdw) {
        margin-left: 0;
        float: initial;
        max-width: 100%;
    }
}

#sections section {

    &.section--text.article {
         padding:0 15px;
    }

    &.section--title .article {
        h1 {
            // font-family: 'Oxygen', sans-serif;
            //            text-transform: uppercase;
            //            font-weight: 600;
            margin: 0;
            font-size: 30px;
            
            @media (min-width: 768px) {
                font-size: 40px;
            }
        }
       
    }

    &.article {

        @media (min-width: $md) {
            font-size: $font-size-lg;
        }

        font-size: $font-size-md;
        //font-family: 'Montserrat',sans-serif;
        font-weight: 100;
        line-height: 1.6;
        color: $text-color;

        h2,
        .h2,
        h3,
        .h3,
        h4,
        .h4,
        h5,
        .h5,
        h6,
        .h6 {
            padding-top: 1.5em;
            margin: 1em 0 1em;
            font-family: 'Arial', sans-serif;
        }

        h2:first-of-type {
            margin-top: 0;
        }

        h2,
        .h2 {
            font-size: 27px;

            @media (min-width: 768px) {
                font-size: 27px;
            }
        }

        h3,
        .h3 {
            font-size: 20px;

            @media (min-width: 768px) {
                font-size: 20px;
            }
        }

        h4,
        .h4 {
            font-size: 1em;
        }

        h5,
        .h5 {
            font-size: 0.95em;
        }

        padding-top: 40px;
        padding-bottom: 60px;
        padding-left: 30px;
        padding-right: 30px;

        @media (max-width: 576px) {
            padding-top: 20px;
            padding-left: 15px;
            padding-right: 15px;
            padding-bottom: 30px;
        }

        //background: $white;

        /*h2:not(.h3),
        .h2,
        h2.border-left,
        h3.border-left {

            border-left: solid 10px $primary;

            padding-top: .8em;
            padding-bottom: .6em;

            padding-left: 15px;
            padding-right: 15px;
            border-radius: 0;

            margin-left: -30px;
            margin-right: -30px;

            @media (max-width: 576px) {
                margin-left: -15px;
                margin-right: -15px;
            }
        }

        h2.alert,
        h3:not(.h2),
        .h3,
        h3.alert {

            padding-top: .55em !important;
            padding-left: 15px;
            padding-right: 15px;
            padding-bottom: .5em !important;
            color: $dark;

            margin-left: -15px;
            margin-right: -15px;

            @media (max-width: 576px) {
                margin-left: -15px;
                margin-right: -15px;
            }
        }*/


        p {
            @media (max-width: 576px) {
                margin-left: 0;
                margin-right: 0;
            }

        }

        ul,
        ol {
            font-size: .97em;
            margin-top: 1em;
            margin-bottom: 1em;
            padding-left: 45px;
            padding-right: 30px;

            @media (max-width: 576px) {
                padding-left: 30px;
                padding-right: 15px;
            }


            li {
                padding-bottom: .3em;
                padding-top: .3em;
                line-height: 1.5em;
                padding-left: .3em;

            }

            ol,
            ul {
                margin-top: 0;
                padding-left: 1.2em;
                padding-right: 0;
            }
        }

        ol {
            list-style: none;
            counter-reset: item;
            padding-left: 0;

            & > li {
                counter-increment: item;
                position: relative;
                padding-left: 30px;

                &:before {
                    content: counter(item);
                    display: inline-block;
                    font-family: sans-serif;
                    text-align: center;
                    font-weight: 700;
                    border-radius: 50%;
                    background: lighten($warning, 20%);
                    color: $black;
                    font-size: 12px;
                    top: 0.65em;

                    position: absolute;
                    left: 0;
                    line-height: 23px;
                    padding: 0 .25em;
                    min-width: 20px;

                    @media screen and (min-width: $sm) {
                        height: 20px;
                        width: 20px;
                        padding: 0 0;
                    }



                }
            }

            ol {
                counter-reset: subitem;
                padding-left: 2em;

                li {
                    counter-increment: subitem;

                    &:before {
                        padding: .3em .2em;
                        line-height: 14px;
                        content: counter(subitem, lower-latin);
                        font-weight: 400;
                        border-radius: 50%;
                        background: lighten($warning, 25%);
                        color: $black;
                        font-size: 12px;
                        top: 0.65em;
                    }
                }
            }
        }

    }

}
