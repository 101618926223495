// Body
$body-bg: #fff;

//Simples Colorsets
//$black : #000000 !default;
$white : #ffffff !default;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Links
$a-color-primary: #3b78e7; // $a-color-primary: #00c800;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Arial", sans-serif; // 'Martel Sans', Montserrat, Raleway
$font-size-base: 14px;
$font-size-md: 16px;
$font-size-lg: 18px;

$line-height-base: 1.5;
$text-color: #454c57;
$border-color: lighten($text-color, 50%);

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

// Navbar
$navbar-default-bg: #fff;
$nav-menu-active: $primary-dark;

// Buttons
$btn-secondary-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);
$input-height: 40px;

// Panels
$panel-default-heading-bg: #fff;
