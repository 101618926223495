#sections {
    nav {
        //padding: 40px 20px;
    }
    
    /* test typo headings sections site public */
    h1,.h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        font-family: 'Arial', sans-serif;
    }
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        margin-bottom: .5em;
    }
//    section:last-of-type article {
//        padding-bottom: 40px;
//    } 
    
    nav,
    section {
        // background: #fff;
        // margin: 0 -60px 20px;
        /*padding: 40px 20px;*/
        /* border: 1px dashed transparent;
        border-left: 0;
        border-right: 0;*/
        position: relative;

        &:hover {
            .btn-edit {
                // display: inline-block;
            }
        }

        .edit-bar {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 110;
            padding: 15px; //background: linear-gradient(0deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0.07) 100%);
            // background: rgba(0,0,0,0.3);
            .btn-edit {
                border: solid 2px $gray-300;
                box-shadow: 0px 2px 3px 0 rgba(0, 0, 0, 0.2);

                &:hover {
                    background: #fff;
                    border-color: $gray-500; //transform: scale(1.1);
                    box-shadow: 0px 2px 3px 0 rgba(0, 0, 0, 0.3);
                } //border-radius: 0px 5px 0px 0px;
            }
        }

        .btn-save {
            float: right;
        }
        &.section--blue,
        &.section--discrete, // &.section--normal,
        &.section--orange {
            .section-bg {
                .btn-edit {
                    //margin-top: 20px;
                }
            }
        }

        .img-full img {
            min-height: 180px;
            max-height: 486px;
            height: auto;
            width: 100%;
            max-width: $md; //rustine pour mettre la largeur de l'image à celle de l'article
            object-fit: cover;
            object-position: center;
        }

        .container,
        .container.xl {

            h1,
            .h1 {
                margin-top: 0;
            }


            h1:last-child {
                margin-bottom: 0;
            }

            & > h2,
            & > h3 {
                margin-top: 11px;
            }

            .row {
                margin-left: -15px;
                margin-right: -15px;
            }
            & p:last-child,
            .row > div p:last-child {
                margin-bottom: 0;
            }


        }

        



        &.section--title {
            //padding: 40px 0;
            // margin-bottom: -10px;
        }

        &.section--separator {
            //padding: 40px 0;
        }

        &.section--javascript {
            padding: 40px 0 20px;
        }

        &.section--normal {
            background: #fff;
        }

        &.section--discrete {
            background: linear-gradient(135deg, #eaeaea 0%, #F8F8F8 100%);
        }

        &.section--orange {
            background: linear-gradient(-45deg, #ff5722, #e91e63);

            .fa-inverse {
                color: #E91E63;
            }

            a.btn {
                text-decoration: initial;
                background: #fff;
                color: #e91e63 !important;
                border-color: #fff;
            }
        }

        &.section--blue {
            background: linear-gradient(-45deg, lighten($primary, 5%), darken($primary, 10%));

            .fa-inverse {
                color: #1f9dd6;
            }

            .btn {
                text-decoration: initial;
                background: #fff;
                color: $primary !important;
                border-color: #fff;
            }
        }


        &.section--blue,
        &.section--orange {
            color: #fff;

            .btn-share,
            a,
            h1,
            h2,
            h3,
            h4,
            h5 {
                color: #fff;
            }

            .text-muted {
                color: rgba(255, 255, 255, 0.8) !important;
            }

            hr {
                border-top: 1px solid rgba(255, 255, 255, 0.7);
            }

            .lead {
                color: #F8F8F8;

                strong {
                    color: #F8F8F8;
                }
            }

            .text-muted {
                color: #eee;
            }

            .comments .post-comments {
                background: #fff;
            }

            &.testimonials .testimonial,
            .testimonials .testimonial {
                strong {
                    color: #fff;
                }

                blockquote {
                    color: #7b7977;
                }
            }

            p a {
                text-decoration: underline;
            }
            p:last-of-type {
                margin-bottom: 0;
            }

            .img-avatar {
                border-color: transparent;

                > div {
                    border-color: transparent;
                }
            }
        }
    }
    
    
    .section-bg {
        background-size: cover;
        background-repeat: no-repeat;
    }

    section {
        &:before,
        &:after {
            content: '';
            display: block;
            overflow: hidden;
        }
        
    }

}

.form-horizontal #sections {
    border-top: 1px solid rgba(204, 204, 204, .8);
    border-bottom: 1px solid #e0e0e0;
    margin: 20px -25px;
    background: #fafafa;

    & > div {
        // padding: 45px 60px 20px;
        //border-top: 1px solid #e0e0e0;
        //padding: 1px;
        //background: #fff;
    }

    nav,
    section {
        //margin-top: 1px;
        transition: all 0.3s ease;
        overflow: hidden; //border-top: solid 5px rgba(0,0,0,0.3);
        border: solid 3px $gray-300; // &:hover {
        //     border: 1px dashed #ccc !important;
        // }
        &.section--noedit {
            //     border: 1px dashed transparent !important;
        }

        &.section--edit {
            border: solid 3px $primary;



            .btn-save {
                background: $primary;
                color: #fff !important;
                border: solid 2px $primary;
                box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.2);

                &:hover {
                    background: darken($primary, 10%); //transform: scale(1.1);
                    box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.3);

                }

            }
        }

        .section-form {
            padding: 10px 0 0;
            clear: both;
        }
    }
}

// defs des max-width pour les sections
#sections .large {
    width: 100%;
    max-width: 100%;
}

#sections .normal {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
}

#sections .petit {
    width: 100%;
    max-width: 992px;
    margin: 0 auto;
}

#sections .plus-petit {
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
}
