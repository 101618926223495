// Fonts
// public : paragraphs
// @import url(https://fonts.googleapis.com/css?family=Martel+Sans:300,400,700&display=swap); // public : headings
// @import url(https://fonts.googleapis.com/css?family=Oxygen:400,700&display=swap); // public articles : headings - menu principal
// //@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap); //side bar + site abonné & admin
// @import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap);
@import "rebootstrap";
@import '~bootstrap/scss/bootstrap';
@import "variables";
@import 'collapse-text'; //afficher/masquer avis client
@import 'pricing-table'; //ok
@import 'plans'; //section détail de l'offre public et customer
@import 'buttons'; // les boutons principaux public
@import 'global';


@import 'comments'; // commentaires des visiteurs public + aperçu dans éditeur d'article
@import "sections"; //sections public et section edit admin
@import "social-sharing";
@import "suggested-articles";
@import 'articles';


@import 'checkbox';
@import 'form';
// @import "nav-tabs";
@import 'table';
@import 'testimonials';
@import 'timer';
@import 'registration-box';
// @import 'treeview-box';
// @import 'motifs';
//@import 'tooltips';

html.public,
html.public body {
    background-color: #fff;
    // color: #636b6f;
    height: 100vh;
    margin: 0;
    text-size-adjust: none;
}

.public,
html.public,
html.public body {
    color: $text-color;
    font-family: $font-family-sans-serif;
    //font-family: apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue","Arial","Roboto",sans-serif;
    font-size: $font-size-md;

    /* @media (min-width: $sm) {
        font-size: $font-size-md;
    }

    @media (min-width: $md) {
        font-size: $font-size-md;
    }*/

    line-height: $line-height-base;
    font-weight: 400;
    //letter-spacing: -.015em;
}

.text-color {
    color: inherit !important;
}

// zoom fonction pour éccran très large
//html.public body {
//    // padding-top: 130px;
//    // @media (max-width: 575px) {
//    //     padding-top: 45px;
//    // }
//
//    // test zoom pour écran 4k
//    /* @media (min-width:2000px) {
//        zoom: 1.33;
//    }
//    @media (min-width:3000px) {
//        zoom: 1.5;
//    }
//    @media (min-width:4000px) {
//        zoom: 1.77;
//    }*/
//}

.public {

    .lead {
        color: inherit;
        font-weight: 100;

        strong {
            color: #555;
        }
    }

    footer {
        //padding: 20px 0 40px;
        background-color: #333;
        color: #fff;

        a {
            color: #fff;
        }

        .footer-breadcrumb {
            background: #f8f8f8;
            color: #666;
            //margin: -20px 0 40px;
            //padding: 10px 0 5px;
            border-top: 1px solid rgba(204, 204, 204, 0.6);
            border-bottom: 1px solid #fff;
            box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.06);

            .breadcrumb {
                padding-left: 0;
                margin-bottom: 5px;
                background-color: transparent;
                margin-bottom: 0;

                li {
                    font-size: 13px;

                    a {
                        color: lighten($text-color, 20%);
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }

                    &:last-of-type a {
                        color: $primary;
                        text-decoration: none;
                    }
                }
            }
        }


        .smartphone {
            display: block;
            width: 31px;
            height: 56px;
            filter: invert(1);
            background-size: contain;
            background-repeat: no-repeat;
            background-attachment: scroll;
            background-position: center center;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMC43NDIgMzguMjk5Ij48cmVjdCB4PSIwLjM3NSIgeT0iMC4zNzUiIHdpZHRoPSIxOS45OTIiIGhlaWdodD0iMzcuNTQ5IiByeD0iMi44MjkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjAuNzUiLz48Y2lyY2xlIGN4PSIxMC40MzEiIGN5PSIzNC4yMTMiIHI9IjEuNzkyIiBmaWxsPSJub25lIiBzdHJva2U9IiMwMDAiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLXdpZHRoPSIwLjc1Ii8+PHBhdGggZD0iTTcuNTQ2LDEuODkzaDUuNzcxYTAsMCwwLDAsMSwwLDB2MGEuNzM0LjczNCwwLDAsMS0uNzM0LjczNEg4LjI4YS43MzQuNzM0LDAsMCwxLS43MzQtLjczNHYwYTAsMCwwLDAsMSwwLDBaIi8+PC9zdmc+");
        }

    }

    //    .full-height {
    //        height: 100vh;
    //    }

    //    .flex-center {
    //        align-items: center;
    //        display: flex;
    //        justify-content: center;
    //    }

    //    .position-ref {
    //        position: relative;
    //    }

    //    .top-right {
    //        position: absolute;
    //        right: 10px;
    //        top: 18px;
    //    }

    //    .content {
    //        text-align: center;
    //    }

    .navbar-pe-blue .navbar-nav > li > a:focus,
    .navbar-pe-blue .navbar-nav > li > a:hover {
        background-color: #F8F8F8;
    }

    #app-dv {
        nav.navbar {
            margin-bottom: 0;

            .navbar-collapse {
                padding: 20px 0;

                @media(min-width:$lg) {
                    padding: 0;
                }
            }

            &.navbar-pe-blue {
                padding: 0;
                background-color: $primary;

                * {
                    color: #fff;
                }

                .navbar-collapse {
                    background-color: $primary;
                }

                .navbar-toggler {
                    margin-right: 15px;
                    // padding: 0.25rem;
                    background-color: transparent;
                    border-color: transparent;
                    // font-size: .8rem;
                    border-radius: 0;

                    .fa-bars,
                    .navbar-toggler-icon {
                        color: #fff;
                    }
                }

                .navbar-text {
                    padding: 0;
                    margin: 0;
                }

                .nav-link {
                    padding: 13px 20px 10px;
                }

                .dropdown-menu {
                    margin-top: 0;
                    padding: 10px 0;
                    border-top: 0;
                    border-radius: 0;
                    border-bottom-left-radius: 3px;
                    border-bottom-right-radius: 3px;
                    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.12);

                    .dropdown-item {
                        padding: 8px 18px 6px 14px;

                        &:hover {
                            background: #F2F2F2;
                        }

                        color: #3b78e7;

                        * {
                            color: #3b78e7;
                        }
                    }
                }
            }

            a {

                &:focus,
                &:hover {
                    background: rgba(255, 255, 255, 0.08);
                }
            }

            .open > a,
            .open > a:focus,
            .open > a:hover {
                background-color: rgba(255, 255, 255, 0.08);
            }

            .links {
                a {
                    padding: 13px 20px 10px;
                    font-size: 15px;
                    font-weight: 800;
                    letter-spacing: initial;
                    text-transform: none;
                    // margin-bottom: -1px !important;
                    // border-color: #3b78e7;
                    border-color: #fff;
                    transition: background 0.3s ease;
                    transition: font-size 0.3s ease;
                    border-bottom: 0;

                    &:focus,
                    &:hover {
                        border-bottom: 0 solid #fff;
                    }
                }

                .active {
                    margin-bottom: initial;
                    position: relative;

                    a {
                        color: $nav-menu-active;
                    }

                    @media screen and (max-width: 990px) {
                        a {
                            &::after {
                                border: 0 !important;
                            }
                        }
                    }
                }
            }

            .navbar-brand {
                margin-right: 0;
                padding: 0 15px;

                &:hover {}

                @media screen and (max-width: 768px) {
                    flex-basis: fit-content;
                    text-align: center;
                }

                img[src="/images/logo-pe-white-short.svg"] {
                    display: block;
                }

                img[src="/images/logo-pe-simple.svg"] {
                    display: none;
                }
            }
        }

        .navbar-text {
            @media(min-width:$lg) {
                padding: 0;
            }

            padding: 10px 20px 0;
            margin: 0;
        }

        .navbar-header-logo {
            height: 0;
            overflow: hidden;
            background: #fff;
            transition: height 0.3s;

            a {
                display: block;
                // display: none;
                padding: 15px 0;
                text-align: center;
                text-decoration: none;

                i {
                    color: #999;
                }
            }
        }

        .navbar-shrink {
            border-top-color: #fff;

            .navbar-header-logo {
                padding-top: 5px;
                height: 85px;
                background: rgba(255, 255, 255, 1);

                a {}
            }

            .navbar-pe-blue {
                border-top: 0 !important;

                .nav-link {
                    font-size: 16px;
                }
            }
        }
    }

    body.registration {
        padding-top: 0;
        background: #fcfcfc;

        .fixed-top {
            position: inherit;
            border-top-color: #fff !important;

            .navbar-header-logo {
                padding-top: 5px !important;
                height: 85px !important;
                background: rgba(255, 255, 255, 1) !important;
            }

            .navbar-pe-blue {
                border-top: 0 !important;

                .nav-link {
                    font-size: 15px !important;
                }
            }
        }

        .navbar-header-logo {
            display: none !important;
        }

        .navbar-pe-blue {
            background-color: #FFF !important;
            border-bottom: 1px solid #ddd;

            .navbar-collapse {
                background-color: #FFF !important;
            }

            .navbar-brand {

                //     background-color: #fff !important;
                img[src="/images/logo-pe-white-short.svg"] {
                    display: none !important;
                }

                img[src="/images/logo-pe-simple.svg"] {
                    display: block !important;
                }
            }

            * {
                // display: none;
                color: #333 !important;
            }

            .links {
                color: #000 !important;

                .active {
                    background-color: #F8F8F8 !important;
                }

                a {
                    font-weight: 400 !important;

                    &::after {
                        border-top-color: #ddd !important;
                    }
                }
            }
        }

        .registration-box {
            margin-top: 40px;

            .price .text-highlight {
                font-size: 1.8em;
                color: #000;
            }
        }

        &.body-with-qcm {
            .navbar-shrink .navbar {
                position: absolute !important;
                top: 0 !important;
                z-index: 1030 !important;
                width: 100%;

                .nav-item.active {
                    .nav-link:after {
                        border: 0 !important;
                    }
                }
            }

            #modal-mcq {
                @media screen and (min-width: 990px) {
                    position: fixed !important;
                    top: 75px !important;
                    z-index: 1 !important;

                    .modal-mask {
                        top: 75px !important;
                        height: calc(100% - 75px) !important;

                        .modal-container {
                            top: 75px !important;
                            height: calc(100% - 75px) !important;
                        }
                    }
                }

                @media screen and (max-width: 990px) {
                    // 32px
                    top: 0 !important;

                    .modal-mask {
                        position: fixed !important;
                        top: 0 !important;
                        height: 100% !important;

                        .modal-container {
                            position: fixed !important;
                            top: 0 !important;
                            height: 100% !important;
                        }
                    }


                }
            }
        }
    }

    .links {
        a {
            display: inline-block;
            color: #636b6f;
            padding: 10px 20px;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0.1rem;
            text-decoration: none;
            text-transform: uppercase;
            white-space: nowrap;

            &.active {
                border-bottom: 3px solid #666;
                margin-bottom: 10px;
                color: #3b78e7;
            }

            &:hover {
                color: #3b78e7;
                border-color: #3b78e7;
            }
        }
    }
}


.ico-payment {
    position: relative;
    margin-top: -2px;
    margin-right: 6px;
    display: inline-block;
    width: 31px;
    height: 24px;
    float: left;
    background-repeat: no-repeat;
}

.ico-cb {
    background-image: url("data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAB8AAAAbCAYAAACEP1QvAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAKiSURBVEiJ7ZVLSFRRHIe/O/fecWZ8lpMPKhepmRG0cYw2JUVEGwOVCIosCFq0y0KzB7QJaiNEUUG7glTIChS0ULLCwtRCpUILszQjJx8z6tyZe++cFmo4MSIJt5Xf7nfO4f+d8z8HDqywwn9EikiHh88AWy20dXN37dX5qERMCnYjsdcyuWAN8EduWziXlSpnWCYGMlPk9QtzRNv7RvRWM8wOq+SyjdaN6WrBfI5oe3aaolklniOivhJtxcRMmCv1UzztDZKdqnD/5CqEgIM3xslIlklwSjzu0mgoS6b7m87ZWh8fhg1y0hVuHktkW6ad98MGR26P41QlXlxwR92JLdpgksvGucJ4ur7o9A7pANxrC/CoU+PoDhe3WmbQDTDCgv1VYzhViefn3ZTkO0lPkgGoqPHROaDz6lNo0TZElQPEOSTssoQZBr8mqKjxcWpfLBPTYb6PmxR5HLwfNgiEBFNBQYZbprIwjoxkmZd9IZ70BNmeZccMQyAk/k0OEKOCEYZLD/3YJDhXGM+DN7PXVuxxsnOTnT1bYng3qOO5OMrXXyYA5dU+TuxykbdBBWBmOXKHKvFjwuRa0zRVhxKIjZGo6wiQnaawZZ2CXZFoOL2a4wUuBr0m1a8D1HVotPWH8GuCzoHZK5vSliG3KxJ+TVCQa6ck30nHgM6g16R4ruVVjVO0f9ZJcs2WyUlTqKz14dmgosog5pzTwejyqK99npQEG0FdcL00EYCmHo1VsTaK8pwMjZnceTZDebUfd7yNywcSmAwIvP4w9WVuslIVbrdM83HEIGhEl0cghGgS1tK46MmNyX4Hwlx6l8tFkh2LysebS3P1n+2WudUUz+aFOeLB6T/buywzA/rom7cL819fqmhGkryW2YXotqz2CissxW+EOVimpL4amgAAAABJRU5ErkJggg==");
}

.ico-secure {
    background-image: url("data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAACAAAAAcCAMAAAA3HE0QAAAA1VBMVEVMaXH7v2Lc3Nz5wWqqqqrb29v7v2L7v2L7v2Lc3NzLs42wsLDSwqjHt53X19eqqqrT09PY2Njcy7LS0tLV1dWtra3by7Hc3NywsLDPz8+tra2srKyurq6vr6+rq6vX19eqqqqqqqrc3NywsLDc3NzW1tbY2NjT09P7v2LPz8/Q0NC0tLTDw8OysrLLy8uwsLCzs7PCwsK/v7/AwMC2tra8vLy3t7e4uLjKysrGxsbOzs6+vr6srKzBwcHR0dG7u7vFxcWtra3T09O6urrJycnMzMyqqqoeaX8lAAAAKHRSTlMAEASEwBAgQIAQttDIyLBQ8FDI0JAgyDDA8MDAwMDAYBBgYPCgwMDA9VgkDwAAAAlwSFlzAAALEgAACxIB0t1+/AAAAMFJREFUKJHtzlcOwjAQBNANJbEpCb337hKH0HuH+x8JAUECbA6AxHyNNE/ahfd0urF2C76lEaNnIU7kYGKlqFLBx9PJcbATKWkEgGyeT5lzcfZs4i7i0gxgiq3drNfACjvjWVKawbfhI8e6tfSKzalPApjyUfhRc4xT+U1MOAs8arnv0qACLJ+gZA+JCgxtDxTsAZFPBMm674GiEmDqPkGF8U+gI+RPRKIhdE8oGsn4EdJfgNFTxPiD3wSaoYgGAABXOlpAJ1oDybwAAAAASUVORK5CYII=");
    background-size: 25px 20px;
    background-position: center;
}

.ico-check-sun {
    background-image: url("data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAB8AAAAbCAYAAACEP1QvAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAO+SURBVEiJ7ZZNTFxVFMd/974POwIFijBIwa8ioaDFpkYK2tiVSTXRhVhj1MRETCkbJTHdabp108aPdJgQNjU2atJFm5iwUFtjMCDWD0z5ai1JBygMxZli6Mh7b+51AfNRmGGmpO56Vu+e97/nd+69751z4a5twqa6rSZ9FLnZ+XlPvPg1dvr4apAdQqvvZ/zy/c3CRb7C6YA5pqHP0t4nupC5+JL8CcQuAW4c+Ux1hzt0LWjuU1q3o+WJ6k534I7A53spchzjxqpeAVeA2jRJCC1iCF0HIDSvV3XGT+WKa+YDd5etOoRKJCrNbQ21tr8FuaUMFbuOEx6o8f4eSeq10NX5xM0KDwWtZkOpWq11lRbqaQC7soXi1uNYFU+u0zvhIRb7u3DmBgDROnPC2mPd406Uv80/2RhZt30qYJwU8GZi7Kt7g5L9PQhpZV2JVg7R8+8Qm/giHTEWLfOaGg/irNVn/doNLXoTz3ZlS04wgJA2Jft7sP3NqYQEfZnAG8Lv7/R+ADEKUNx6PCc4EruRTGBr67GEO66E91m2OVnhoYD1FOhCc1tjxjNOt7H5v+i58BUDod8AsP3NmKU7ASZtH7N5w7VGzHTLdyXqR6DG9u/dEDyxMMnZ8e8whKSyqCLpt/0tALXxJfOXmSD1ecGng9YurcWHsFLR5Jay5Ds37nF6pI+L4UsATEZCnBn7FkNI2hoP8FDJ9lRg332J5dRrZfRPdVtNOeHVHe4fQsZ3AxcA1L8LKbhyicQW+WbiHOcnBzk90ochJAcfe4EH08AAKjafePRAfLp91v1zLSvrrzZ7koL4kvG7WdpQW/HqcNJ/041xavgs129GsAyTtoYD68AA4S8b8aLjIYVsqzns/pyJkb2xLFMBPOxFRnDCQ0n3vZaP1x5/kcrCcl5pfD4j2JkbxIuOgxax6g53aJ0gFzzume8BBsBifxdaucl3BbaPt3a/zAPFVevmaeWw2N+1MhC67lrQ3Hdb8NX2+VxqJQNEz7ejVcZacQs4eq4dJ5zaZaV1ezb9hl1tvpcid9mq00J9ALxk+5vZ2nrslgqWSnBwpbavgM+gdb8QYiYu5eWaQ+7gbcMTNh2QR0B8lBibpTux/XuRvnJUbB5nbgAvMpoKKuWeqkPur7ni5tVShRZTOpGmFhNeZNTnRUZr0iSXgUdYOUZtme6lfOLmdY1SyCsaPhdCPFt12KtXyDYBq1+gHjYK4k+YOv6oRnwMYsM2ekdsOiCPTAeMhatBdqT71971/hfTR5GZSuZdy8f+A5q9a0CXclx3AAAAAElFTkSuQmCC");
}

.ico-folder {
    background-image: url("data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAB8AAAAbCAYAAACEP1QvAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAALQSURBVEiJ7ZaxTxNxFMe/73d3PaAcZ1vbLq0opg5SoaZpa0NBiIsRMS63mLi4ujr4F5iYEAY3BxIHwsDg6H9gTGxDRNB0sNbGBMFBUKjQ8rs+F0MotOWKEBe+27337n1+7+793u8HnOo/SPnXBJZlKbquD4dCodDy8vLXdt5VjwKMxWJnVFW9ycwTpVLpthCiB8AMgNcnAo/FYhFFUSaEEHeYOcPMSjgclmNjY2o+n8f8/Hy83SKomcOyLKVQKMSEEBOKotyVUg4SEUej0dro6KgyPDyMvr4+AMDs7CympqZqAB4ACBNRH4CLAMq5XO5WM0bDylOpVE+xWPxMRD632y1HRkbUTCaDdDpNhmEc6JNIJAIAAsALIoLH46natq2Vy+Xldr7ErhKJxKvx8XFp2zYfpvX1dY7H4zw9Pc2VSoWZmScnJzmZTG60YohmDmZ+vrKyoiwtLR26UNM0EQgEsLa2BpfLBQAwDAO2bbsty2q6o3b/OTOHAdz7+9gtpdTn5uYe9vb2dg0NDQFcA9eqTVNks1lIKZFOpwGuofSlgHw+j+uZay9dul4FAKF1dpLa/ZiI8vvh57gmi+WFKcE7m2CWh1YMAFz9BWbbUayZeWaT0MJE9K0ODgAst8srMxe6alvfHSVrR6LjLIL3S7+F2uHete0NqFU3Pmne6LGDAUDz9oN3Ngt1C6qLUPW3mm/gROCqtx8Q2nxTuNCMrMsfL58EXPMNbgtXT64pHMB7zR931mntwv1XKwA+tIIvCuN8F8SRzpuWUs1Leks4EZVZbv9QzcixgkVnEBCqJKK6bXRgwhHvLGreK8cK17yXwdX6Tm8Md5lvNN+As6nhUKqnH1DqO70hHEJ9pwWSx9rxmj+2JVxmbr+9UWctaIGE3pN64iixk1Gsh24wgI/77QcuE8wsADxq5AOA1dVVMxgM/txjMuDsRvSUiNYcxJ3qZPUHSzMPG3JPfRwAAAAASUVORK5CYII=");
}
